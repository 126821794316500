.LinkPrimary {
  display: block
}

.LinkPrimary .LinkPrimary-link {
    display: inline-flex;
    align-items: center;
    text-align: left;
    text-decoration: none;

    color: var(--color-black);
    cursor: pointer;

    transition: color 300ms ease-in-out
  }

.LinkPrimary .LinkPrimary-link:hover {
      color: var(--color-blue-01);
    }

.LinkPrimary .LinkPrimary-text {
    font-size: 16px;
  }

.LinkPrimary .LinkPrimary-icon {
    color: var(--color-blue-01);
    font-size: 14px;
  }

.LinkPrimary .LinkPrimary-icon--left {
    margin-right: 10px;
  }

.LinkPrimary .LinkPrimary-icon--right {
    margin-left: 10px;
  }

.LinkPrimary--grey .LinkPrimary-icon {
  color: var(--color-grey-03);
}

.LinkPrimary--disabled .LinkPrimary-link {
    cursor: not-allowed;
    color: var(--color-grey-07)
  }

.LinkPrimary--disabled .LinkPrimary-link:hover {
      color: var(--color-grey-07);
    }

.LinkPrimary--disabled .LinkPrimary-icon {
    color: var(--color-grey-07);
  }
