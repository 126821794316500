.Header {
  display: block
}

.Header.is-toggled .Header-header {
      border-bottom: 1px solid var(--color-blue-01);
      background-color: var(--color-white);
    }

.Header.is-toggled .Header-meta {
      background-color: var(--color-white);
    }

.Header.is-toggled .Header-backdrop {
      opacity: 1;
      pointer-events: all;
    }

.Header.is-toggled .Header-nav {
      transform: translate(0, 100%);
    }

.Header-backdrop {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;

  height: 100vh;

  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);

  opacity: 0;
  pointer-events: none;

  transition: opacity 200ms ease-in-out
}

@media (min-width: 1024px) {

.Header-backdrop {
    display: none
}
  }

.Header-meta {
  position: relative;
  z-index: 999;

  display: none;
  padding-top: 15px
}

@media (min-width: 640px) {

.Header-meta {
    display: block
}
  }

.Header-contact {
  display: flex;
  justify-content: flex-end
}

.Header-contact a {
    color: var(--color-blue-01)
  }

.Header-contact a:hover,
    .Header-contact a:focus {
      color: var(--color-blue-02);
      text-decoration: underline;
    }

.Header-contactIcon {
  height: 16px;
  width: 16px;
  margin-right: 5px;
  fill: var(--color-blue-01);
}

.Header-contactPhone,
.Header-contactMail {
  display: flex;
  align-items: center;
  margin: 0 0 0 20px;
}

.Header-header {
  position: relative;
  z-index: 999;
  border-bottom: 1px solid transparent;
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs)
}

@media (min-width: 640px) {

.Header-header {
    padding-top: 10px
}
  }

.Header-container {
  display: flex;
}

.Header-logo {
  margin-right: auto;
}

.Header-logoImage {
  display: block;
  width: auto;
  height: 50px
}

@media (min-width: 1024px) {

.Header-logoImage {
    height: 55px
}
  }

.Header-nav {
  position: absolute;
  bottom: -1px;
  right: 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 320px;
  height: calc(100vh - 100% - 1px);
  padding: 10px 0 0 0;

  transform: translateY(100%) translateX(100%) translateX(9px);
  background-color: var(--color-white);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.33);

  transition: transform 200ms ease-in-out
}

.Header-nav > ul {
    margin: 0;
    padding: 0;
    height: 100%;
  }

@media (min-width: 1024px) {

.Header-nav {
    position: static;

    display: block;
    width: auto;
    height: auto;
    padding: 0;
    overflow: visible;

    background-color: transparent;

    transform: none;
    box-shadow: none
}

    .Header-nav > ul {
      display: flex;
    }
  }

.HeaderNavLink {
  display: block;
  border-top: 1px solid var(--color-grey-07);

  list-style: none
}

.HeaderNavLink:first-child {
    border-top: none;
  }

@media (min-width: 1024px) {

.HeaderNavLink {
    border-top: none
}
  }

.HeaderNavLink-link {
  display: flex;
  align-items: center;

  width: 100%;
  padding: 15px;
  background: none;
  border: none;
  cursor: pointer;

  font-size: 16px;
  text-decoration: none;
  color: var(--color-grey-01);

  transition: color 100ms ease-in-out
}

.HeaderNavLink-link:hover,
  .HeaderNavLink-link:focus {
    outline: none;
    color: var(--color-blue-01);
    text-decoration: none;
  }

@media (min-width: 1024px) {

.HeaderNavLink-link {
    height: 100%;
    padding: 0;

    font-size: 18px
}
  }

.HeaderNavLink-children {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;
  padding: 10px 0 0 0;
  margin: 0;

  background: var(--color-white);

  transform: translateX(100%);
  transition: transform 200ms ease-in-out
}

@media (min-width: 1024px) {

.HeaderNavLink-children {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;

    width: 380px;
    padding: 15px 0;

    border-radius: 15px 0 15px 15px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(100%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease-in-out
}
  }
.HeaderNavLink-back {
  display: flex;
  align-items: center;

  width: 100%;
  padding: 15px;
  background: none;
  border: none;
  border-bottom: 1px solid var(--color-grey-07);
  cursor: pointer;

  font-size: 16px;
  text-decoration: none;
  color: var(--color-black);

  transition: color 100ms ease-in-out
}
.HeaderNavLink-back::before {
    font-family: iconfont;
    content: "\f102";

    font-size: 12px;
    color: var(--color-blue-01);
    margin-right: 10px;
  }
.HeaderNavLink-back:hover,
  .HeaderNavLink-back:focus {
    outline: none;
    color: var(--color-blue-01);
  }
@media (min-width: 1024px) {
.HeaderNavLink-back {
    display: none
}
  }

.HeaderNavLink-summaryLink {
  display: block;
  padding: 15px;
  margin-bottom: 30px;

  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: var(--color-black);

  transition: color 100ms ease-in-out
}

.HeaderNavLink-summaryLink:hover,
  .HeaderNavLink-summaryLink:focus {
    text-decoration: none;
    color: var(--color-blue-01);
  }

@media (min-width: 1024px) {

.HeaderNavLink-summaryLink {
    font-size: 20px;
    margin: 0 0 15px 0;
    padding: 15px 30px
}
  }

.HeaderNavLink-childList {
  padding: 0;
  margin: 0;
}

.HeaderNavLink--hasChildren > .HeaderNavLink-link::after {
    font-family: iconfont;
    content: "\f103";

    margin-left: auto;

    font-size: 12px;
    color: var(--color-grey-07);

    transition: color 100ms ease-in-out
  }

@media (min-width: 1024px) {

.HeaderNavLink--hasChildren > .HeaderNavLink-link::after {
      display: none
  }
    }

.HeaderNavLink--hasChildren > .HeaderNavLink-link:hover::after, .HeaderNavLink--hasChildren > .HeaderNavLink-link:focus::after {
      color: var(--color-blue-01);
    }

@media (min-width: 1024px) {

.HeaderNavLink--firstLevel {
    position: relative;

    display: block;
    height: 100%;
    margin-left: 20px
}
  }

@media (min-width: 1366px) {

.HeaderNavLink--firstLevel {
    margin-left: 30px
}
  }

@media (min-width: 1366px) {

.HeaderNavLink--firstLevel .HeaderNavLink-link {
      font-size: 18px
  }
    }

@media (min-width: 1024px) {
        .HeaderNavLink:not(.HeaderNavLink--firstLevel).HeaderNavLink--current .HeaderNavLink-link:hover,
        .HeaderNavLink:not(.HeaderNavLink--firstLevel).HeaderNavLink--current .HeaderNavLink-link:focus {
          color: var(--color-blue-01);
          background: var(--color-grey-09);
        }
      }

@media (min-width: 1024px) {

.HeaderNavLink:not(.HeaderNavLink--firstLevel) .HeaderNavLink-link {
      padding: 15px 30px
  }

      .HeaderNavLink:not(.HeaderNavLink--firstLevel) .HeaderNavLink-link:hover,
      .HeaderNavLink:not(.HeaderNavLink--firstLevel) .HeaderNavLink-link:focus {
        color: var(--color-black);
        background: var(--color-grey-09);
      }
    }

.HeaderNavLink--current > .HeaderNavLink-link,
.HeaderNavLink--active > .HeaderNavLink-link {
  font-weight: 700;
  color: var(--color-blue-01);
}

@media (min-width: 1024px) {

.HeaderNavLink.is-open {
    box-shadow: inset 0 -4px 0 0 var(--color-blue-01)
}

    .HeaderNavLink.is-open::after {
      opacity: 1;
    }
  }

@media (min-width: 1024px) {

.HeaderNavLink.is-open > .HeaderNavLink-link {
      color: var(--color-blue-01)
  }
    }

.HeaderNavLink.is-open .HeaderNavLink-children {
    transform: translateX(0)
  }

@media (min-width: 1024px) {

.HeaderNavLink.is-open .HeaderNavLink-children {
      opacity: 1;
      pointer-events: all;
      transform: translateY(100%)
  }
    }

.Header-burgerToggle {
  align-self: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 6px 6px 2px 6px;
  border-radius: 5px;

  cursor: pointer;
  background: none;
  border: 1px solid transparent;

  transform: scale(1);
  transition: transform ease-in-out 100ms, border-color ease-in-out 100ms
}

.Header-burgerToggle:hover,
  .Header-burgerToggle:focus {
    outline: none;
    transform: scale(1.05);
  }

@media (min-width: 1024px) {

.Header-burgerToggle {
    display: none
}
  }

.Header-burgerToggleLabel {
  font-size: 10px;
  color: var(--color-black);
  margin-top: auto;
}

.Header-burgerToggleLine {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;

  background: var(--color-blue-01);
  opacity: 1;
  transform: none;

  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
  will-change: transform
}

.Header-burgerToggleLine + .Header-burgerToggleLine {
    margin-top: 5px;
  }

.Header-burgerToggle.is-active {
  border-color: var(--color-blue-01)
}

.Header-burgerToggle.is-active .Header-burgerToggleLine:nth-child(1) {
      transform: translateY(7px) rotate(-45deg);
    }

.Header-burgerToggle.is-active .Header-burgerToggleLine:nth-child(2) {
      opacity: 0;
    }

.Header-burgerToggle.is-active .Header-burgerToggleLine:nth-child(3) {
      transform: translateY(-7px) rotate(45deg);
    }
