.Gallery {
}

.Gallery-image {
  --columns: 6;

  position: relative;
  overflow: hidden;
  border-radius: 10px;

  background-repeat: no-repeat;
  background-position: center
}

@media (min-width: 640px) {

.Gallery-image {
    --columns: 4
}
  }

@media (min-width: 768px) {

.Gallery-image {
    --columns: 3
}
  }

.Gallery-image::after {
    content: "";
    display: block;
    padding-top: 100%;
  }

.Gallery-image img {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

.Gallery--cover .Gallery-image {
  background-size: cover;
}

.Gallery--contain .Gallery-image {
  background-size: 80%;
}
