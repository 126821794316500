.Section {
  display: block;
  padding-top: var(--spacing-md);
  padding-bottom: var(--spacing-md);
}

.Section--highlight {
  max-width: 1340px;
  background-color: var(--color-blue-04);
  margin: auto;
  border-radius: 20px;
}

.Section-intro {
  display: block;
  margin-bottom: var(--spacing-md);
}

.Section-intro .Copytext {
  display: block;
  margin-top: 10px;
  margin-bottom: var(--spacing-xs);
}

.Section-content {
  display: block
}

.Section-content > * + *:not(script) {
    margin-top: var(--spacing-l);
  }
