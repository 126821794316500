.Titleimage {
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-l);
}

.Titleimage-container {
  position: relative;

  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xl);
  align-items: center;
  grid-row-gap: var(--spacing-sm);
  grid-row-gap: var(--spacing-sm);
  row-gap: var(--spacing-sm);
}

.Titleimage-background {
  position: absolute;
  left: calc((7680px - 100vw / 2) * -1);
  right: calc((7680px - 100vw / 2) * -1);
  bottom: -50px;

  display: block;
  width: 7680px;
  margin: auto;

  pointer-events: none;
}

.Titleimage-content {
  --columns: 12;
  position: relative
}

@media (min-width: 768px) {

.Titleimage-content {
    --columns: 6
}
  }

.Titleimage-headline {
  margin-bottom: var(--spacing-xxs);
}

.Titleimage-text {
  margin-bottom: var(--spacing-xs);
}

.Titleimage-imageWrapper {
  --columns: 12;

  z-index: 1;
  position: relative;
  padding: 0
}

@media (min-width: 768px) {

.Titleimage-imageWrapper {
    --columns: 6
}
  }

.Titleimage-image {
  overflow: hidden;
  border-radius: 50px 0 50px 50px;
}
