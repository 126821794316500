@font-face {
  font-family: "iconfont";
  src: url("../fonts/iconfont/iconfont.eot") format("embedded-opentype"),
    url("../fonts/iconfont/iconfont.svg") format("svg"),
    url("../fonts/iconfont/iconfont.ttf") format("truetype"),
    url("../fonts/iconfont/iconfont.woff") format("woff"),
    url("../fonts/iconfont/iconfont.woff2") format("woff2");
}

*[class^="u-Icon--"]::before,
*[class*=" u-Icon--"]::before {
  font-family: iconfont !important;
  font-style: normal;
  font-weight: normal !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-Icon--arrow-down::before {
  content: "\f101";
}
.u-Icon--arrow-left::before {
  content: "\f102";
}
.u-Icon--arrow-right::before {
  content: "\f103";
}
.u-Icon--arrow-up::before {
  content: "\f104";
}
.u-Icon--checkmark::before {
  content: "\f105";
}
.u-Icon--download-small::before {
  content: "\f106";
}
.u-Icon--envelope::before {
  content: "\f107";
}
.u-Icon--globe::before {
  content: "\f108";
}
.u-Icon--home::before {
  content: "\f109";
}
.u-Icon--i-circle::before {
  content: "\f10a";
}
.u-Icon--pin::before {
  content: "\f10b";
}
.u-Icon--quote::before {
  content: "\f10c";
}
.u-Icon--x-circle::before {
  content: "\f10d";
}
.u-Icon--x::before {
  content: "\f10e";
}
