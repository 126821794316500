@import "./breakpoints";

@font-face {
  font-family: "NeuzeitOffice";
  src: url("../fonts/neuzeit/NeuzeitOffice.eot");
  src: url("../fonts/neuzeit/NeuzeitOffice.eot") format("eot"),
    url("../fonts/neuzeit/NeuzeitOffice.woff2") format("woff2"),
    url("../fonts/neuzeit/NeuzeitOffice.woff") format("woff"),
    url("../fonts/neuzeit/NeuzeitOffice.ttf") format("truetype"),
    url("../fonts/neuzeit/NeuzeitOffice.svg") format("svg");
  font-weight: 400;
}

@font-face {
  font-family: "NeuzeitOffice";
  src: url("../fonts/neuzeit/NeuzeitOffice-italic.eot");
  src: url("../fonts/neuzeit/NeuzeitOffice-italic.eot") format("eot"),
    url("../fonts/neuzeit/NeuzeitOffice-italic.woff2") format("woff2"),
    url("../fonts/neuzeit/NeuzeitOffice-italic.woff") format("woff"),
    url("../fonts/neuzeit/NeuzeitOffice-italic.ttf") format("truetype"),
    url("../fonts/neuzeit/NeuzeitOffice-italic.svg") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "NeuzeitOffice";
  src: url("../fonts/neuzeit/NeuzeitOffice-bold.eot");
  src: url("../fonts/neuzeit/NeuzeitOffice-bold.eot") format("eot"),
    url("../fonts/neuzeit/NeuzeitOffice-bold.woff2") format("woff2"),
    url("../fonts/neuzeit/NeuzeitOffice-bold.woff") format("woff"),
    url("../fonts/neuzeit/NeuzeitOffice-bold.ttf") format("truetype"),
    url("../fonts/neuzeit/NeuzeitOffice-bold.svg") format("svg");
  font-weight: 700;
}

@font-face {
  font-family: "NeuzeitOffice";
  src: url("../fonts/neuzeit/NeuzeitOffice-bold-italic.eot");
  src: url("../fonts/neuzeit/NeuzeitOffice-bold-italic.eot") format("eot"),
    url("../fonts/neuzeit/NeuzeitOffice-bold-italic.woff2") format("woff2"),
    url("../fonts/neuzeit/NeuzeitOffice-bold-italic.woff") format("woff"),
    url("../fonts/neuzeit/NeuzeitOffice-bold-italic.ttf") format("truetype"),
    url("../fonts/neuzeit/NeuzeitOffice-bold-italic.svg") format("svg");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "NeuzeitOfficeRound";
  src: url("../fonts/neuzeit/NeuzeitOfficeRound.eot");
  src: url("../fonts/neuzeit/NeuzeitOfficeRound.eot") format("eot"),
    url("../fonts/neuzeit/NeuzeitOfficeRound.woff2") format("woff2"),
    url("../fonts/neuzeit/NeuzeitOfficeRound.woff") format("woff"),
    url("../fonts/neuzeit/NeuzeitOfficeRound.ttf") format("truetype"),
    url("../fonts/neuzeit/NeuzeitOfficeRound.svg") format("svg");
  font-weight: 400;
}

@font-face {
  font-family: "NeuzeitOfficeRound";
  src: url("../fonts/neuzeit/NeuzeitOfficeRound-bold.eot");
  src: url("../fonts/neuzeit/NeuzeitOfficeRound-bold.eot") format("eot"),
    url("../fonts/neuzeit/NeuzeitOfficeRound-bold.woff2") format("woff2"),
    url("../fonts/neuzeit/NeuzeitOfficeRound-bold.woff") format("woff"),
    url("../fonts/neuzeit/NeuzeitOfficeRound-bold.ttf") format("truetype"),
    url("../fonts/neuzeit/NeuzeitOfficeRound-bold.svg") format("svg");
  font-weight: 700;
}

:root {
  --fontsize-4xl: 40px;
  --fontsize-3xl: 32px;
  --fontsize-2xl: 26px;
  --fontsize-xl: 22px;
  --fontsize-l: 18px;
  --fontsize-m: 16px;
  --fontsize-s: 14px;
  --fontsize-xs: 12px
}

@media (min-width: 768px) {

:root {
    --fontsize-4xl: 42px;
    --fontsize-3xl: 34px;
    --fontsize-2xl: 28px;
    --fontsize-xl: 23px;
    --fontsize-l: 19px;
    --fontsize-m: 17px;
    --fontsize-s: 15px;
    --fontsize-xs: 13px
}
  }

@media (min-width: 1366px) {

:root {
    --fontsize-4xl: 45px;
    --fontsize-3xl: 36px;
    --fontsize-2xl: 30px;
    --fontsize-xl: 25px;
    --fontsize-l: 20px;
    --fontsize-m: 18px;
    --fontsize-s: 16px;
    --fontsize-xs: 14px
}
  }
