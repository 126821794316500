.Feedback {
  --columns: 12;

  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;

  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--color-white)
}

@media (min-width: 640px) {

.Feedback {
    --columns: 6
}
  }

@media (min-width: 768px) {

.Feedback {
    --columns: 4
}
  }

.Feedback--big {
  --columns: 12
}

@media (min-width: 640px) {

.Feedback--big {
    --columns: 6
}
  }

@media (min-width: 768px) {

.Feedback--big {
    --columns: 8
}
  }

.Feedback-icon {
  display: block;
  margin-bottom: 10px;

  font-size: 20px;
  color: var(--color-grey-07)
}

@media (min-width: 768px) {

.Feedback-icon {
    font-size: 30px
}
  }

.Feedback-content {
  font-style: italic;
  font-size: var(--fontsize-l);
  color: var(--color-blue-01);

  margin: 0;
}

.Feedback-cite {
  margin-top: auto;

  font-size: var(--fontsize-m);
  color: var(--color-grey-04)
}

.Feedback-cite::before {
    content: "";

    display: block;
    width: 100px;
    height: 1px;
    margin: var(--spacing-xxs) 0;

    background-color: var(--color-blue-01);
  }
