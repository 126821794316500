@import "./breakpoints";

:root {
  --spacing-grid-row: 10px;
  --spacing-grid-col: 10px;
  --spacing-xxs: 15px;
  --spacing-xs: 20px;
  --spacing-sm: 20px;
  --spacing-md: 40px;
  --spacing-l: 50px;
  --spacing-xl: 50px
}

@media (min-width: 640px) {

:root {
    --spacing-grid-row: 20px;
    --spacing-grid-col: 20px;
    --spacing-xxs: 15px;
    --spacing-xs: 20px;
    --spacing-sm: 20px;
    --spacing-md: 40px;
    --spacing-l: 50px;
    --spacing-xl: 60px
}
  }

@media (min-width: 768px) {

:root {
    --spacing-grid-row: 20px;
    --spacing-grid-col: 20px;
    --spacing-xxs: 20px;
    --spacing-xs: 30px;
    --spacing-sm: 30px;
    --spacing-md: 50px;
    --spacing-l: 60px;
    --spacing-xl: 80px
}
  }

@media (min-width: 1024px) {

:root {
    --spacing-grid-row: 40px;
    --spacing-grid-col: 40px;
    --spacing-xxs: 20px;
    --spacing-xs: 30px;
    --spacing-sm: 30px;
    --spacing-md: 50px;
    --spacing-l: 60px;
    --spacing-xl: 80px
}
  }

@media (min-width: 1366px) {

:root {
    --spacing-grid-row: 40px;
    --spacing-grid-col: 40px;
    --spacing-xxs: 20px;
    --spacing-xs: 30px;
    --spacing-sm: 40px;
    --spacing-md: 50px;
    --spacing-l: 60px;
    --spacing-xl: 100px
}
  }
