.Copytext {
  color: var(--color-grey-01);
  text-align: left;
  font-size: var(--fontsize-m);
  line-height: 1.5
}

.Copytext p {
    margin: 0;
    margin-bottom: 1em;
  }

.Copytext p:last-child {
    margin-bottom: 0;
  }

.Copytext ol {
    counter-reset: ol;
    list-style: none
  }

.Copytext ol > li {
      counter-increment: ol;
      margin-bottom: 5px
    }

.Copytext ol > li::before {
        content: counter(ol) ".";

        display: inline-block;
        margin-right: 15px;
        margin-left: -27px;

        font-weight: 700;
        color: var(--color-blue-01);
      }

.Copytext ul {
    list-style: none
  }

.Copytext ul > li {
      margin-bottom: 5px
    }

.Copytext ul > li::before {
        font-family: iconfont;
        content: "\f103";
        font-size: 12px;

        display: inline-block;
        margin-right: 15px;
        margin-left: -27px;

        color: var(--color-blue-01);
      }

.Copytext--s {
  font-size: var(--fontsize-s);
}

.Copytext--l {
  font-size: var(--fontsize-l);
}

.Copytext--xl {
  font-size: var(--fontsize-xl);
}
