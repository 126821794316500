.NewsTeaserList {
  display: block;
}

.NewsTeaserList-items {
  grid-row-gap: 40px;
  row-gap: 40px
}

.NewsTeaserList-items > * {
    --columns: 12;
    grid-column-end: span var(--columns);
    grid-column-start: auto
  }

@media (min-width: 640px) {

.NewsTeaserList-items > * {
      --columns: 6
  }
    }

@media (min-width: 768px) {

.NewsTeaserList-items > * {
      --columns: 4
  }
    }
