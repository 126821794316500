*:not(pre) {
  font-family: "NeuzeitOffice", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Helvetica Neue", "Arial", sans-serif;
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;

  background-color: var(--color-blue-03);
}

a {
  color: var(--color-blue-01);
  font-weight: 500;
  text-decoration: none
}

a:hover,
  a:focus {
    text-decoration: underline;
  }
