.NewsTeaser {
  overflow: hidden;

  height: 100%;
  padding: 15px 15px 20px 15px;
  border-radius: 10px;

  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--color-white);
}

.NewsTeaser-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.NewsTeaser-imageLink {
  position: relative;

  overflow: hidden;
  display: block;
  width: calc(100% + 30px);
  margin: -15px -15px 20px -15px;
}

.NewsTeaser-image {
  display: block;
  width: 100%;
  height: auto;
}

.NewsTeaser-content {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.NewsTeaser-date {
  font-size: var(--fontsize-s);
  color: var(--color-grey-03);
  margin-bottom: 10px;
}

.NewsTeaser-headline,
.NewsTeaser-headline a {
  font-size: var(--fontsize-l);
  font-weight: 400;
  color: var(--color-blue-01);
  text-decoration: none;
}

.NewsTeaser-headline {
  margin: 0 0 10px 0;
}

.NewsTeaser-text {
  font-size: var(--fontsize-s);
  margin-bottom: auto;
}

.NewsTeaser-link {
  font-size: var(--fontsize-m);
  margin-top: 15px;
  margin-right: auto
}

@media (min-width: 768px) {

.NewsTeaser-link {
    font-size: var(--fontsize-s)
}
  }
