:root {
  --color-grey-01: #1e1e1e;
  --color-grey-02: #414141;
  --color-grey-03: #5d5d5d;
  --color-grey-04: #888888;
  --color-grey-05: #a5a5a5;
  --color-grey-06: #c3c3c3;
  --color-grey-07: #d7d7d7;
  --color-grey-08: #ebebeb;
  --color-grey-09: #f5f5f5;
  --color-grey-10: #fafafa;

  --color-white: #ffffff;
  --color-black: #000000;

  --color-blue-01: #0052a3;
  --color-blue-02: #0b88d9;
  --color-blue-03: #f9f9ff;
  --color-blue-04: #d9e0f4;

  --color-yellow-01: #fac600;
}
