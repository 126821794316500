.Quote {
  display: block;
  text-align: center;
}

.Quote-icon {
  display: block;
  margin-bottom: var(--spacing-xs);

  font-size: 54px;
  color: var(--color-grey-07)
}

@media (min-width: 768px) {

.Quote-icon {
    font-size: 72px
}
  }

.Quote-content {
  font-style: italic;
  font-size: var(--fontsize-2xl);
  color: var(--color-blue-01);

  margin: 0;
}

.Quote-cite {
  font-size: var(--fontsize-l);
  color: var(--color-grey-04)
}

.Quote-cite::before {
    content: "";

    display: block;
    width: 150px;
    height: 1px;
    margin: var(--spacing-xxs) auto;

    background-color: var(--color-blue-01);
  }
