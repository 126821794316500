@import "./breakpoints";

:root {
  --layout-content-maxWidth: 1140px;
  --layout-content-padding: 15px
}

@media (min-width: 640px) {

:root {
    --layout-content-padding: 24px
}
  }

@media (min-width: 768px) {

:root {
    --layout-content-padding: 22px
}
  }

@media (min-width: 1024px) {

:root {
    --layout-content-padding: 52px
}
  }

@media (min-width: 1366px) {

:root {
    --layout-content-padding: 103px
}
  }

.u-Container {
  box-sizing: border-box;

  max-width: calc(
    1140px + (2 * 15px)
  );

  max-width: calc(
    var(--layout-content-maxWidth) + (2 * var(--layout-content-padding))
  );

  margin-left: auto;
  margin-right: auto;

  padding-left: 15px;

  padding-left: var(--layout-content-padding);
  padding-right: 15px;
  padding-right: var(--layout-content-padding);
}
