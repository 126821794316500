.Contact {
  display: block;
  padding: 30px var(--layout-content-padding);
  background-color: var(--color-white)
}

@media (min-width: 768px) {

.Contact {
    padding: 50px var(--layout-content-padding)
}
  }

.Contact-mapWrapper {
  --columns: 12;
  position: relative;
  min-height: 300px
}

@media (min-width: 768px) {

.Contact-mapWrapper {
    --columns: 6;
    min-height: 250px
}
  }

.Contact-mapContainer {
  position: absolute;
  top: 30px;
  right: calc(var(--layout-content-padding) * -1);
  bottom: -30px;
  left: calc(var(--layout-content-padding) * -1);
  background-color: var(--color-grey-07)
}

@media (min-width: 768px) {

.Contact-mapContainer {
    top: -50px;
    right: 0;
    bottom: -50px;
    left: calc(var(--layout-content-padding) * -1)
}
  }

.Contact-map {
  width: 100%;
  height: 100%;
}

.Contact-content {
  --columns: 12;
  max-width: var(--layout-content-maxWidth)
}

@media (min-width: 768px) {

.Contact-content {
    --columns: 6;
    max-width: calc(var(--layout-content-maxWidth) / 2);
    padding: 0
}
  }

@media (min-width: 768px) {
    .Contact--left .Contact-mapWrapper {
      order: 0;
    }

    .Contact--left .Contact-content {
      order: 1;
    }
  }

@media (min-width: 768px) {
    .Contact--right .Contact-mapWrapper {
      order: 1;
    }

    .Contact--right .Contact-mapContainer {
      top: -50px;
      right: calc(var(--layout-content-padding) * -1);
      bottom: -50px;
      left: 0;
    }

    .Contact--right .Contact-content {
      order: 0;
    }
  }
