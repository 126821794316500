.Footer {
  margin-top: 50px;
  border-top: 5px solid var(--color-blue-02);
  background-color: var(--color-white);
}

.Footer-container {
  padding-top: 35px;
  padding-bottom: 35px
}

@media (min-width: 640px) {

.Footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}
  }

@media (min-width: 1024px) {

.Footer-container {
    flex-wrap: nowrap
}
  }

.Footer-section {
  margin-bottom: 50px
}

.Footer-section:last-child {
    margin-bottom: 0;
  }

@media (min-width: 640px) {

.Footer-section {
    margin-bottom: 0
}
  }

.Footer-section.Footer-section--logo {
    flex: 0 0 100%;
    margin-left: auto;
    margin-right: auto
  }

@media (min-width: 640px) {

.Footer-section.Footer-section--logo {
      margin-bottom: 30px
  }
    }

@media (min-width: 1024px) {

.Footer-section.Footer-section--logo {
      flex: 0 0 128px;
      margin: 0 auto 0 0
  }
    }

@media (min-width: 640px) {

.Footer-section.Footer-section--nav {
      width: 360px
  }
    }

@media (min-width: 640px) {

.Footer-section.Footer-section--contact {
      margin-left: auto
  }
    }
.Footer-sectionTitle {
  margin: 0 0 10px 0;
  font-size: 14px;
  font-weight: 700;
  text-align: center
}
@media (min-width: 640px) {
.Footer-sectionTitle {
    text-align: left
}
  }

.Footer-logo {
  display: block;
  margin-bottom: 15px;
  text-align: center
}

@media (min-width: 1024px) {

.Footer-logo {
    margin-bottom: 25px
}
  }

.Footer-logoImage {
  width: 128px;
}

.Footer-social {
  display: flex;
  justify-content: center;
}

.Footer-socialLink {
  margin-right: 15px
}

.Footer-socialLink:last-child {
    margin: 0;
  }

.Footer-socialLink svg {
    width: 24px;
    height: 24px;
  }

.Footer-socialLink path {
    fill: var(--color-grey-03);
  }

.Footer-nav ul {
    margin: 0;
    padding: 0;
    list-style: none;

    text-align: center;
    columns: 1
  }

@media (min-width: 640px) {

.Footer-nav ul {
      text-align: left
  }
    }

@media (min-width: 768px) {

.Footer-nav ul {
      columns: 2
  }
    }

.Footer-navLink {
  display: block;
  padding: 5px 0;
}

.Footer-contact {
  font-size: 16px;
  color: var(--color-grey-03);
  text-align: center
}

@media (min-width: 640px) {

.Footer-contact {
    text-align: left
}
  }

.Footer-meta {
  padding: 10px 0;
  background-color: var(--color-grey-03);
  color: var(--color-white);
}

.Footer-copyright {
  font-size: 14px;
  text-align: right;
}
