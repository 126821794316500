.ButtonPrimary {
  display: inline-block
}

.ButtonPrimary .ButtonPrimary-button {
    font-size: 16px;
    padding: 12px 30px;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    border-radius: 5px;
    background-color: var(--color-blue-01);
    border: 1px solid var(--color-blue-01);
    color: var(--color-white);
    cursor: pointer;

    transition: color 300ms ease-in-out, background-color 300ms ease-in-out
  }

.ButtonPrimary .ButtonPrimary-button:hover {
      color: var(--color-blue-01);
      background-color: var(--color-white);
    }

.ButtonPrimary .ButtonPrimary-icon {
    font-size: 18px;
  }

.ButtonPrimary .ButtonPrimary-icon--left {
    margin-left: -5px;
    margin-right: 8px;
  }

.ButtonPrimary .ButtonPrimary-icon--right {
    margin-left: 8px;
    margin-right: -5px;
  }

.ButtonPrimary--grey .ButtonPrimary-button {
  background-color: var(--color-grey-03);
  border-color: var(--color-grey-03);
  color: var(--color-white)
}

.ButtonPrimary--grey .ButtonPrimary-button:hover {
    color: var(--color-grey-03);
  }

.ButtonPrimary--inverted .ButtonPrimary-button {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-blue-01)
}

.ButtonPrimary--inverted .ButtonPrimary-button:hover {
    color: var(--color-white);
  }

.ButtonPrimary--disabled .ButtonPrimary-button {
  cursor: not-allowed;
  border-color: var(--color-grey-09);
  background-color: var(--color-grey-09);
  color: var(--color-grey-07)
}

.ButtonPrimary--disabled .ButtonPrimary-button:hover {
    background-color: var(--color-grey-09);
    color: var(--color-grey-07);
  }

.ButtonPrimary--hasIcon .ButtonPrimary-button {
  padding-left: 20px;
  padding-right: 20px;
}
