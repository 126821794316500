.HeadlinePrimary,
.HeadlinePrimary * {
  display: block;
  text-align: left;

  color: var(--color-blue-01);

  font-size: var(--fontsize-4xl);
  font-weight: 700;
  margin: 0;
}
