.HeadlineTertiary,
.HeadlineTertiary * {
  display: block;
  text-align: left;

  color: var(--color-grey-01);

  font-size: var(--fontsize-1xl);
  font-weight: 400;
  margin: 0;
}
