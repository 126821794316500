.Image {
  display: block;
  width: 100%;
  height: auto
}
.Image img {
    display: block;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;

    -o-object-fit: inherit;

       object-fit: inherit;
    -o-object-position: inherit;
       object-position: inherit;
  }
