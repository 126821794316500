.MediaText {
  display: block
}

.MediaText .MediaText-media {
    --columns: 12;

    align-self: flex-start;
    border-radius: 5px;
    overflow: hidden;
  }

.MediaText .MediaText-content {
    --columns: 12;

    align-self: flex-start;
  }

.MediaText--s .MediaText-media {
    --columns: 12
  }

@media (min-width: 768px) {

.MediaText--s .MediaText-media {
      --columns: 4
  }
    }

@media (min-width: 1024px) {

.MediaText--s .MediaText-media {
      --columns: 4
  }
    }

.MediaText--s .MediaText-content {
    --columns: 12
  }

@media (min-width: 768px) {

.MediaText--s .MediaText-content {
      --columns: 8
  }
    }

@media (min-width: 1024px) {

.MediaText--s .MediaText-content {
      --columns: 8
  }
    }

.MediaText--m .MediaText-media {
    --columns: 12
  }

@media (min-width: 768px) {

.MediaText--m .MediaText-media {
      --columns: 6
  }
    }

.MediaText--m .MediaText-content {
    --columns: 12
  }

@media (min-width: 768px) {

.MediaText--m .MediaText-content {
      --columns: 6
  }
    }

.MediaText--l .MediaText-media {
    --columns: 12
  }

@media (min-width: 768px) {

.MediaText--l .MediaText-media {
      --columns: 6
  }
    }

@media (min-width: 1024px) {

.MediaText--l .MediaText-media {
      --columns: 8
  }
    }

.MediaText--l .MediaText-content {
    --columns: 12
  }

@media (min-width: 768px) {

.MediaText--l .MediaText-content {
      --columns: 6
  }
    }

@media (min-width: 1024px) {

.MediaText--l .MediaText-content {
      --columns: 4
  }
    }

@media (min-width: 768px) {
    .MediaText--left .MediaText-media {
      order: 0;
    }

    .MediaText--left .MediaText-content {
      order: 1;
    }
  }

@media (min-width: 768px) {
    .MediaText--right .MediaText-media {
      order: 1;
    }

    .MediaText--right .MediaText-content {
      order: 0;
    }
  }
