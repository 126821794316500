@import "./spacings";
@import "./breakpoints";

:root {
  --spacing-grid-col: 10px;
  --spacing-grid-row: 10px
}

@media (min-width: 640px) {

:root {
    --spacing-grid-col: 20px;
    --spacing-grid-row: 20px
}
  }

@media (min-width: 768px) {

:root {
    --spacing-grid-col: 20px;
    --spacing-grid-row: 20px
}
  }

@media (min-width: 1024px) {

:root {
    --spacing-grid-col: 40px;
    --spacing-grid-row: 40px
}
  }

@media (min-width: 1366px) {

:root {
    --spacing-grid-col: 40px;
    --spacing-grid-row: 40px
}
  }

.u-GridContainer {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 10px;
  column-gap: 10px;
  grid-column-gap: var(--spacing-grid-col);
  column-gap: var(--spacing-grid-col);
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-row-gap: var(--spacing-grid-row);
  row-gap: var(--spacing-grid-row);
}

.u-GridItem {
  --columns: 12;

  grid-column-end: span var(--columns);
  grid-column-start: auto;
}
